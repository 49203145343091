<template>
  <div>
    <section id="header" class="logo-bg">
      <div class="logo inner-wrapper">
        <a href="https://cubes.wtf">
          <img src="/images/logo.png" id="logo" alt="meatcubes logo"/>
        </a>
      </div>
    </section>
    <section class="green">
      <div class="inner-wrapper">
        <div id="navigation">
          <ul>
            <li><a href="https://cubes.wtf/mint">MINT NOW!</a></li>
            <li><a href="https://cubes.wtf/#about">About</a></li>
            <li><a href="https://cubes.wtf/#roadmap">Roadmap</a></li>
            <li><a href="https://cubes.wtf/#team">Team</a></li>
            <li><a href="https://cubes.wtf/kitchen">Test Kitchen</a></li>
          </ul>
        </div>
      </div>
    </section>
 </div>
</template>

<script>
export default {
  name: 'HeaderBlock',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header img {
  width: 100%;
  height: 100%;
}
#logo {
  max-width: 1400px;
  width:100%;
  height: auto;
  border-radius: 10px;
}

#navigation {
  list-style:none;
  margin:0;
  padding:0;
  text-align:center;
  height: auto;
}

#navigation li{
  display:inline;
  text-align: center;
}

#navigation a{
  display:inline-block;
  padding:10px 50px;
}
.logo-bg {
  background-image: url("https://cubes.wtf/images/logo_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
