<template>
  <div class="inner-wrapper inner-padding">
    <h1>"Meat" the Team</h1>
    <div class="flex-container">
      <div class="about-section">
        <a href="https://twitter.com/KillerNapkins" target="_blank">
          <div>
            <img src="images/team/killer-napkins.jpg" alt="KillerNapkins Profile Picture">
          </div>
          <h4>Artist - Killer Napkins</h4>
        </a>
      </div>
      <div class="about-section">
        <a href="https://twitter.com/MetaverseMogul" target="_blank">
          <div>
            <img src="images/team/metaversemogul-ape.png" alt="MetaverseMogul Profile Picture">
          </div>
          <h4>Developer - MetaverseMogul</h4>
        </a>
      </div>
      <div class="about-section">
        <a href="https://twitter.com/TheScoopy1" target="_blank">
          <div>
            <img src="images/team/scoopy-ape.png" alt="Scoopy Profile Picture">
          </div>
          <h4>Community Manager - Scoopy</h4>
        </a>
      </div>
    </div>
    <h1>Collaborators</h1>
    <div class="flex-container">
      <div class="about-section">
        <a href="https://twitter.com/TheArtDude_" target="_blank">
          <div>
            <img src="images/team/theartdude-ape.jpg" alt="TheArtDude Profile Picture">
          </div>
          <h4>Artist - TheArtDude_</h4>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//const contractAddress = '0x22e471060bf8C52eA25A33F26801a8781b6F33eB'; // CONTRACT ADDRESS!!!
// test contract   0x89D195a983F3363d8Af692c9c7aFaDE9b123f236      chain 4
// real contract   0x22e471060bf8C52eA25A33F26801a8781b6F33eB      chain 1
//const abi = require('../../public/abis/api.json');
//const Web3 = require('web3');
//const web3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/4c10bb441a3d45d19143c54df642fee4');

export default {
  name: 'Team',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    color: black;
    text-decoration: none;
    font-size:1em;
  }
  h1 {
    color:black;
  }

  .about-section {
    padding: .5em;
    text-align: center;
    width:auto;
  }

  .about-section:first-of-type {
    padding-left:0;
  }

  .about-section:last-of-type {
    padding-right:0;
  }

  .about-section img {
    width: 100%;
    height:100%;
    max-width:300px;
    min-width:150px;
    border-radius: 10px;
    object-fit: cover;
  }
  .about-section img:hover {
    transform: scale(1.05);
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
</style>
