<template>
  <footer class="inner-wrapper">
    &copy; 2021, Art by <a href="https://www.twitter.com/killernapkins" target="_blank">Killer Napkins</a>&nbsp;&nbsp;|
    &nbsp;&nbsp;Development by <a href="http://www.blueberrypancakes.xyz" target="_blank">Blueberry Labs</a>
    &nbsp;&nbsp;<a href="https://discord.gg/qAPcAh5hYN" target="_blank"><img class="social discord" src="/images/socials/discord.svg" alt="Join our Discord"></a>
      <a href="https://twitter.com/meatcubesnft" target="_blank"><img class="social twitter" src="/images/socials/twitter.svg" alt="Follow us on Twitter!"></a>
      <a href="https://opensea.io/collection/meatcubes" target="_blank"><img class="social opensea" src="/images/socials/opensea.svg" alt="Find us on OpenSea!"></a>
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    color: white;
    text-decoration: underline;
    font-size:1em;
  }
  a:hover {
    color:#f5f1bb;
  }
  footer {
    text-align: center;
    color:white;
    padding:10px;

  }
  .social {
    max-width: 40px;
    display: inline-block;
  }
  .social {
    padding: 3px;
    position: relative;
    top: 8px;
  }
  .twitter {
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }
  .discord {
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }
</style>
