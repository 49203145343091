<template>
  <div id="roadmap" class="inner-wrapper inner-padding">
    <div class="flex-container">
      <div class="column">
        <h1>ROADMAP</h1>
        <!--<p>
          Cool image goes here
        </p>-->
      </div>
      <div class="column padding-left">
        <div class="timeline">
          <div class="container left">
            <div class="content">
              <h2>25% SOLD - 10 FREE MEATCUBES</h2>
              <p>At the 25% mark, All holders will be airdropped a comic strip of the beginning story for MeatCubes! Additionally to that the holders of one of the initial 1,743 MeatCubes will be eligible to win one of free 10 Meat Cubes Airdropped directly to their wallet!</p>
            </div>
          </div>
          <div class="container right">
            <div class="content">
              <h2>50% SOLD - MERCH</h2>
              <p>4,200 Meaty art posters will be available for purchase at a discounted price from Maddies.co. We will be having Meat Cube Stickers as well on top of that!</p>
            </div>
          </div>
          <div class="container left">
            <div class="content">
              <h2>75% SOLD - WEARABLES</h2>
              <p>
                Wearables snapshot will be taken! Holders will be airdropped wearable costumes for Decentreland!
              </p>
            </div>
          </div>
          <div class="container right">
            <div class="content">
              <h2>AFTER SELL OUT! - TIERED COLABS</h2>
              <p>
                KillerNapkins will work with 4 different artists. A snapshot will be taken and based on the number of MeatCubes held you will be airdropped 1 or more colab NFTs organized by tiers. You will be warned prior to the snapshot in the Discord.
              </p>
              <h4>Hodling Tiers</h4>
              <ul>
                <li>
                  1 Held - Tier 1 Colab
                </li>
                <li>
                  2-4 Held - Tier 1 &amp; 2 Colab
                </li>
                <li>
                  5-9 Held - Tier 1,2 &amp; 3 Colab
                </li>
                <li>
                  10+ Held - Tier 4 and all lower tier colabs
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Roadmap',
  props: {},
}
</script>

<style scoped>
  img {
    width: 100%;
    height: auto;
  }
  h1,h3 {
    margin:0;
  }
  h3 {
    position: relative;
    top:-1em;
  }
  ul {
    left: 30px;
    position: relative;
    width: 95%;
    font-size:1.1em;
  }
  .flex-container {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .padding-left {
    padding-left: 1em;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #000;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }

  /* Container around content */
  .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    color: black;
  }

  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: #fff;
    border: 4px solid black;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  .column {
    padding: 20px 0;
  }

  /* Place the container to the left */
  .left {
    left: 0;
  }

  /* Place the container to the right */
  .right {
    left: 50%;
  }

  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #000;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent black;
  }

  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #000;
    border-width: 10px 10px 10px 0;
    border-color: transparent black transparent transparent;
  }

  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }

  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: #fff;
    position: relative;
    border-radius: 6px;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {

  }

  @media (max-width: 950px){
    .flex-container {
      display: grid;
      grid-template-columns: 100%;
    }
  }
</style>
