<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted: function()
  {
    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    }
  }
}
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}
/* Below are main variables to easily control different attributes of the page */
:root {
  --bg-color: rgb(255, 255, 255);
  --box-color: rgb(80, 204, 80);
  --mint-me-button-color: rgb(82, 158, 82);
  --mint-me-button-alternating-color: green;
  --main-text-color: rgb(38, 112, 38);
  --typewriter-text-color: rgb(38, 112, 38);
  --first-gradient-color: rgb(82, 158, 82);
  --second-gradient-color: rgb(38, 112, 38);
  --third-gradient-color: green;
  --on-gradient-text-color: white;
  --accordion-button-color: rgb(18, 90, 18);
}
html {
  height: 100%;
}
body {
  font-family: 'Staatliches', 'Helvetica', 'Arial', sans-serif, monospace; /* Change font family here */
  overflow-x: hidden;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.5;
  background-color: #4f571f;
  height: 100%;
  width:100%;
}
a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size:1.25em;
}
a:hover {
  color: oldlace;
  cursor: pointer;
}
p {
  font-size: 1.1em;
}
h3 {
  margin-bottom:10px;
}
section {
  width: 100%;
}
.inner-padding {
  padding:5px;
}

.inner-wrapper {
  max-width: 1400px;
  margin-left:auto;
  margin-right:auto;
}
.flex-grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

::-moz-selection { /* Code for Firefox */
  color: darkgreen;
  background: lightpink;
}

::selection {
  color: darkgreen;
  background: lightpink;
}

</style>
