<template>
  <div class="bg-color">
    <header-block></header-block>
    <section>
      <div class="yellow">
        <div id="about" class="inner-wrapper">
          <div class="flex-grid">
            <div class="inner-padding">
              <h1>Cubes.wtf?</h1>
              <p>
                MeatCubes are a generative collection of <span class="highlight">6,969</span> NFTs on the Ethereum blockchain. Each MeatCube is assembled
                from over 150+ randomly chosen parts!
              </p><br />
              <h2>Wen Cubes?</h2>
              <p>
                Meatcubes are <a href="https://cubes.wtf/mint">LIVE NOW!</a><br /><br />
                <a href="https://discord.gg/qAPcAh5hYN" target="_blank">join the Discord</a> and <a href="https://twitter.com/meatcubesnft" target="_blank">Follow us on Twitter</a> to keep up on the latest news from MeatCubes!
              </p>
              <br />
            </div>
            <div class="sample-right sample-body">
              <img src="images/samples/cube_1.png" alt="sample meat cube!" />
            </div>
          </div><br />
          <div id="mint-now-banner" class="yellow">
            <a href="https://cubes.wtf/mint">
              <img src="images/mint-banner.png" alt="Mint your Meatcube Now!" />
            </a>
          </div>
          <br />
        </div>
      </div>
      <div id="roadmap" class="red">
        <roadmap></roadmap><br />
      </div>
      <div id="story" class="yellow">
        <div class="inner-wrapper flex-grid">
          <div id="story-image">
            <br />
            <img src="images/meateor.gif" alt="the meateor, origin of the meatcubes!" />
          </div>
          <div class="inner-padding">
            <br />
            <h2>The Meataverse</h2><br />
            <p>
              There was once a planet named Carnis. Located on the outer rim of some unknown galaxy it was a dumping
              ground for all of the radiation and unwanted junk in the universe. The meat &amp; meat substitute based
              lifeforms of the planet lived peacefully and in harmony until one day An evil figure descended upon them...<br /><br />

              While the citizens of Carnis were celebrating at their annual Carnival of Meats the one known as Brocolli Rabe struck.
              He had long known of a weakness lurking within the temperature control system of Carnis. The system is vital
              for the stability of the planet and is responsible for maintaining an optimal cooking temperature. By modifying
              the temperature module he was able to force the heating laser to overload, blasting Carnis into tiny bits, while
              simultaneously mutating &amp; sending the Meat Cubes rocketing across the universe!
            </p>
          </div>
        </div><br />
      </div>
      <div id="samples" class="red">
        <br />
        <div class="inner-wrapper flex-wrapper">
          <div class="sample">
            <img src="images/samples/cube_2.png" alt="sample meat cube!" />
          </div>
          <div class="sample">
            <img src="images/samples/cube_3.png" alt="sample meat cube!" />
          </div>
          <div class="sample">
            <img src="images/samples/cube_4.png" alt="sample meat cube!" />
          </div>
        </div>
        <br />
      </div>
      <div id="team" class="yellow">
        <team></team><br />
      </div>
      <div class="green">
        <footer-block></footer-block>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Roadmap from "@/components/Roadmap";
import Team from "@/components/Team";
import FooterBlock from "@/components/Footer-Block";
import HeaderBlock from "@/components/Header";
export default {
  name: 'Home',
  components: {
    HeaderBlock,
    FooterBlock,
    Team,
    Roadmap
  }
}
</script>

<style scoped>
  .bg-color {
    background-color: #4f571f;
    height: 100%;
    width:100%;
  }

  .yellow {
    background-color: #f5f1bb;
  }
  .green {
    background-color: #4f571f;
  }

  @media (max-width: 1000px) {
    #about {
      padding: 0 8px;
    }
  }
  @media (max-width: 880px) {
    #navigation {
      text-align:center;
      height: auto;
    }
    #navigation li {
      display:revert;
      text-align: center;
      list-style: none;
      width:100%;
      padding: 5px 0;
      margin:0;
      border-bottom: 1px solid white;
    }
    #navigation li:last-of-type {
      border: none;
    }
    #navigation ul {
      margin-left:-40px;
    }
    #navigation a {
      display:revert;
      padding:15px 50px;
    }
  }

  #app {
    height:100%;
    width:100%;
    min-height: 100%;
  }

  #header h1 {
    text-align: center;
    width: 100%;
  }

  #nav a {
    font-weight: bold;
    color: #ffffff;
  }

  #nav a.router-link-exact-active {
    color: lightcoral;
  }
  .sample {
    margin-left:auto;
    margin-right:auto;
    align-items: center;
  }
  .sample-right {
    margin-left:auto;
    align-items: center;
  }
  .sample img, .sample-right img {
    max-width: 600px;
    max-height: 600px;
    border: 10px #ec9180 solid;
    width:100%;
  }
  #samples .sample img {
    max-width: 440px;
    max-height: 440px;
    border: 10px #f5f1bb solid;
    width:100%;
  }
  #mint-now-banner {
    text-align: center;
  }
  #mint-now-banner img {
    max-width: 85%;
    margin:0;
    border-radius: 10px;
    filter: drop-shadow(5px 5px 4px #202020);
  }
  #story .sample img {
    max-width: 400px;
    max-height: 400px;
    border: 10px #f5f1bb solid;
  }
  #story-image img {
    max-width: 500px;
    max-height: 500px;
    border: 10px #ec9180 solid;
    width:100%;
  }
  .sample-body {
    padding-top: 80px;
  }
  .red {
    background-color: #ec9180;
  }
  #story {
    text-align: left;
  }
  #about a {
    color:black;
    text-decoration: underline;
    font-size: 1em;
  }
  .flex-grid {
    grid-template-columns: 50% 50%;
    justify-content: center;
  }
  #story .flex-grid {
    grid-template-columns:  40% 60%;
  }
  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .center-vertical {
    display: flex;
    position: relative;
    height: 100%;
  }
  @media (max-width: 1200px) {
    .sample img {
      max-width: 500px;
      max-height: 500px;
    }
  }
  @media (max-width: 1000px) {
    .flex-grid {
      grid-template-columns: 100%;
    }
    .sample-body {
      padding-top:0;
    }
    .sample img {
      max-width: 900px;
      max-height: 900px;
      border: 20px #ec9180 solid;
    }
    .sample-right {
      margin-right:auto;
    }
  }
</style>